.mainWrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1vw;

  h2 {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: visible;
    font-size: 2vw;
    color: #27521c;
    font-weight: 650;
    text-transform: uppercase;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.3vw;
    font-weight: 500;
    margin-bottom: 0px;
  }
}
.teamNameWrapper {
  background-color: #ede303;
  width: 100%;
  border-radius: 0.5vw;
  margin-top: 0.2vw;
  padding: 1% 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #27521c;
  .teamName {
    display: flex;
    align-items: center;
    justify-content: start;
    width: auto;
    max-width: 70%;
    h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      white-space: nowrap;
      overflow: visible;
    }
  }

  .tossImg {
    width: 1.8vw;
    // background-color: #fff;
  }
  span {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
    gap: 5px;
    width: max-content;
  }
}
.logoTableContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  .teamLogo {
    width: 11%;
    border-radius: 5%;
    border: solid 2px gray;
  }
}
.tableWrapper {
  width: 97%;
  height: 100%;
  font-size: 15px;

  .battingList {
    width: 100%;
    background-color: #27521c;
    margin-bottom: 2px;
    color: #fff;
    border-radius: 13px;
    padding: 7px 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3%;

    div {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bowlingStat {
      color: #ede303;
    }
  }
}
.extrasRow {
  background-color: #ede303;
  width: 100%;
  border-radius: 0.5vw;
  padding: 1% 2%;
  margin-top: 0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
