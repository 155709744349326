.PlayerInfoWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  // background: grey;

  .PlayerCardWrapper {
    display: flex;
    // background: pink;
    width: 100%;
    align-items: center;

    @media (max-width: 768px) {
      & {
        display: flex;
        flex-direction: column;
      }
    }

    // .PlayerImage {
    //   div {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     border: 2px solid #007237;
    //     border-radius: 5px;
    //     padding: 5px;
    //     margin-right: 15px;
    //     width: 150px;
    //     // width: 100%;
    //      height: 150px;
    //      background: red;

    //   }
    //   img {
    //     width: 145px;
    //    // width: 100%;
    //     height: 145px;
    //    // min-width: 150px;
    //   }
    // }
    .playerImg {
      border: 2px solid #007237;
      border-radius: 5px;
      padding: 5px;
      margin-right: 15px;
      width: 150px;
    }

    .playertextContainer {
      // display: flex;
      width: 100%;
      @media (max-width: 768px) {
        & {
          margin-top: 15px;
        }
      }
      .PlayerInfoSection {
        display: flex;
        //color: #007237;
        width: 100%;
        .PlayerInfoText {
          color: #007237;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 80%;
          min-width: 120px;
          @media (max-width: 550px) {
            & {
              width: 80%;
            }
          }
          p {
            margin: 0px;
          }
        }
        .PlayerInfoText2 {
          color: #007237;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;
          @media (max-width: 550px) {
            & {
              min-width: 150px;
              width: 50%;
            }
          }
          p {
            margin: 0px;
          }
        }
      }
    }
  }
}
.buttonWrapper {
  // background: #000;
  width: 100%;
  display: flex;
  justify-content: end;
}
