.CsaFileUploadWrapper {
 width: 100%;
 margin-bottom: 10px;

 .name {
  color: #000000;
  margin-bottom: 2px;
  font-size: 15px;
 }

 .required {
  color: red;
  margin-left: 5px;
 }
 .btn {
  background-color: #059f4f;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  &:hover {
   background-color: #949494;
  }
 }
 .btnInActive {
  background-color: #949494;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  &:hover {
   background-color: #949494;
  }
 }
}
