.CsaCardsWrapper {
    padding: 20px 25px 20px;
  }
  
  .CsaCardsHeader {
    display: flex;
    justify-content: space-between;
    .heading {
      color: #007237;
      font-size: 25px;
      flex: 1;
    }
  }
  .errContainer {
    width: 100%;
    height: screen;
    display: flex;
    justify-content: center;
    margin: 20px;
    .errImgContainer {
      .errImg {
        width: 300px;
        height: 300px;
      }
      .errText {
        color: #007237;
        font-size: large;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
  