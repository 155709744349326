.TournamentsWrapper {
  padding: 20px 25px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    & {
      padding: 0;
    }
  }

  .TournamentsStatus {
    display: flex;
    color: #007237;

    width: 100%;
    flex: 0 0 auto;
    flex-wrap: wrap;
    border-color: #007237;
    border-width: 5px;

    @media (min-width: 1125px) {
      & {
        flex-wrap: nowrap;
      }
    }
  }
}

.TournamentsWinner {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  position: relative;

  .TournamentsMatchStatus {
    font-weight: 600;
    font-size: 1rem;

    span {
      padding-right: 8px;
      margin-left: 5px;
    }

    @media (max-width: 1124px) {
      & {
        display: none;
      }
    }
  }

  .TournamentsWinnerResult {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
    letter-spacing: 1px;
    color: #007237;

    .TournamentsWinnerName {
      font-weight: 600;
      font-size: 1.5rem;
      color: #007237;
    }

    .TournamentsWinnerStatus {
      font-weight: 600;
      font-size: 1rem;
      color: #007237;
    }

    @media (max-width: 1124px) {
      & {
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .TournamentsWinnerName {
          font-weight: 600;
          font-size: 1rem;
          padding-right: 7px;
        }

        .TournamentsWinnerStatus {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }
  }

  @media (min-width: 1125px) {
    & {
      flex: 1 1 25%;
    }
  }

  @media (max-width: 1124px) {
    & {
      width: 100%;
      flex: 1 1 auto;
    }
  }
}

.TournamentsDivider {
  display: inline-block;
  height: 100%;
  margin: 0 10px;
  min-width: 30px;
  position: relative;

  div {
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #007237;
    opacity: 0.5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }

  @media (min-width: 1125px) {
    & {
      flex: 1 1 5%;
    }
  }

  @media (max-width: 1124px) {
    & {
      display: none;
    }
  }
}

.TournamentsTeam {
  display: flex;

  .TournamentsTeamScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .TournamentsTeamName {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .scoresWrapper {
    display: flex;
  }
  .TournamentsTeamScoreValue {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 20px 0;
    line-height: 1;
  }

  .TournamentsTeamOver {
    line-height: 1;
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.75rem;

      &:last-child {
        padding-top: 5px;
      }
    }
  }

  @media (min-width: 1125px) {
    & {
      flex: 1 1 70%;
    }
  }

  @media (max-width: 1124px) {
    & {
      width: 100%;
      flex: 1 1 auto;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      flex: 1 1 auto;
    }
  }

  @media (max-width: 692px) {
    & {
      .TournamentsTeamScore {
        order: 2;
        width: 100%;
      }

      .TournamentsTeamOver {
        align-items: center;
      }
    }
  }
}

.TournamentsTeamLogo {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007237;
    border-radius: 5px;
    padding: 10px;
    margin-left: 8px;
    margin-right: 8px;
  }

  img {
    width: 100px;
    height: 100px;
  }
}

.TournamentsVersus {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;

  @media (max-width: 692px) {
    & {
      padding-top: 10%;
      align-items: start;
    }
  }
}

.TournamentsHeader {
  display: flex;
  justify-content: space-between;

  .heading {
    color: #007237;
    font-size: 25px;
    flex: 1;
  }
}

.TournamentsTeamDetail {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: start;
  flex-wrap: nowrap;

  &:first-child {
    justify-content: end;
  }

  @media (max-width: 692px) {
    & {
      flex-wrap: wrap;
      justify-content: center;

      &:first-child {
        justify-content: center;
      }
    }
  }
}

.TournamentsData {
  flex: 1 1 auto;
  margin: 20px 0;

  & > div > div:first-child {
    margin-bottom: 0;
    padding: 0 10px;

    &:before {
      border-bottom: none;
    }
  }
}

@media (max-width: 1124px) {
}
.errContainer {
  width: 100%;
  height: screen;
  display: flex;
  justify-content: center;
  margin: 20px;
  .errImgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .errImg {
      width: 300px;
      height: 300px;
    }
    .errText {
      color: #007237;
      font-size: large;
      margin-top: 20px;
      text-align: center;
    }
  }
}
