.wrapper {
 min-height: 100vh;
 background-color: #fff;
 padding-bottom: 5%;
 height: auto;
 transition: all 0.3s ease;
}

.wrapperActive {
 min-height: 100vh;
 background-color: #004f27;
 margin-left: 0px;
 padding-bottom: 5%;
 height: auto;
 transition: all 0.3s ease;
}

@media (min-width: 320px) and (max-width: 900px) {
 .wrapper {
  margin-left: 0px;
 }
 .footerWrap {
  position: unset;
 }
}

.header {
 z-index: 1;
 width: 100%;
 padding: 0;
 height: auto;
}

.footer {
 width: 100%;
 padding: 0;
 height: auto;
 margin: 0;
 background-color: yellow;
}

.list {
 color: #fff;
 font-size: 13px;
 font-weight: 600;
}
::-webkit-scrollbar {
 display: none;
}

.sideNavBg {
 background-image: linear-gradient(90deg, #004f27, #00793a);
 cursor: pointer;
 overflow: scroll;
 width: 101%;
}

.drawer {
 padding: 0 !important;

 .ant-drawer-body {
  padding: 0 !important;
 }
}
.imgWrap {
 //  margin-top: 1%;
 height: 150px;
 width: 150px;
 overflow: hidden;
 img {
  //   height: 150px;
  //   width: 150px;
  object-fit: contain;
  border-radius: 50%;
  transition: all 0.3s ease;
 }
 //  cursor: pointer;
}
.imgWrapCollapsed {
 //  display: none;
 padding: 2%;
 margin: 2%;
 //  height: 60px;
 //  width: 60px;
 //  border-radius: 50%;
 background-color: transparent;
 display: flex;
 align-items: center;
 justify-content: center;
 border: 2px solid linear-gradient(90deg, #004f27, #00793a);
 overflow: hidden;
 transition: all 0.3s ease;
 img {
  height: 80%;
  width: 80%;
  object-fit: contain;
  transition: all 0.3s ease;
 }
 cursor: pointer;
}

.menuName {
 margin-bottom: 0;
 text-overflow: ellipsis;
 width: 110px;
 background-color: transparent !important;
}
