.CsaMoreMenuWrapper {
 .cursor {
  cursor: pointer;
 }
 padding-right: 5%;
 background: transparent !important;
 .menuMore {
  color: #fff !important;
  border: transparent !important;
 }
}
