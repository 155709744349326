
.contentWrapper {
 // background: rgb(79, 79, 255);
  overflow-x: scroll;
  overflow-y: hidden;
  white-space:nowrap;
  width:auto;
  width: 100%;

}
.contentWrapper2{
  max-width:  100%;
  min-width: 600px;
  text-align: left;
}

.ExpandListWrapper {
    width: 100%;
    margin-bottom: 15px;
  
    .ListTitleWrapper {
      width: 100%;
      height: 35px;
      background: #ffcb05;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: left;
  
      .ListTitle {
        color: #000;
        margin-top: auto;
        margin-left: 10px;
        font-weight: 500;
      }
    }
  }
  
  .expand-table {
    .ant-table-thead {
      padding-left: 30px;
    }
  }
  
  .ScheduleMatchWrapper {
    background: rgb(235, 233, 233);
    width: 100%;
    height: auto;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
  
    .TeamSection {
      height: 100%;
      align-items: center;
  
      padding: 6px;
    }
  }
  
  .ScheduleMatchWrapperyellowbg {
    background: #FFFFE0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
  
    .TeamSection {
      height: 100%;
      align-items: center;
  
      padding: 6px;
    }
  }
  
  .MatchInfoSection {
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    border-left: solid;
    border-width: 1px;
    border-color: gray;
    display: flex;
    justify-content: center;
  }
  
  .Teamimg {
    height: 60px;
    width: 60px;
    object-fit: contain;
    border-radius: 10px;
    border-style: solid;
    border-color: white;
    border-width: 3px;
  }
  .ExpandListItems {
    width: 100%;
    height: 45px;
    background: #ffcb05;
    display: flex;
    align-items: center;
    border-radius: 13px;
    padding-left: 10px;
    color: #000;
    margin-bottom: 15px;
   // font-size: 15px;
    font-weight: 500;

  }  
  .ExpandList {
    width: 100%;
    height: 65px;
    background: #ebebeb;
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding-left: 5px;
    color: #000;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;   
    transition: all 0.4s linear;
    &:hover {
      background-color: rgb(252, 255, 228) !important;
    }
    }


  h2 {
    text-align: center;
    padding: 20px 0;
  }
 

  
  // @media screen and (max-width: 667px) {
  //   .contentWrapper{
  //     background: yellow;
  //     overflow-x: auto;
  //     white-space: nowrap;
  //     display: block;
  //   }
  // }

