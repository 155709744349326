.LoginAsWrapper {
 padding: 20px 25px 20px;
 .heading {
  color: #007237;
  font-size: 25px;
 }

 .contents {
  padding: 10px 0;
 }
 .name {
  color: #000000;
  margin-bottom: 2px;
  font-size: 15px;
 }

 .required {
  color: red;
  margin-left: 5px;
 }
}
