.CsaHeaderWrapper {
 overflow-x: hidden;
 display: flex;
 width: 100%;
 background: -webkit-linear-gradient(110deg, #fff 90%, #ffcb05 10%);
 background: -o-linear-gradient(110deg, #fff 90%, #ffcb05 10%);
 background: -moz-linear-gradient(110deg, #fff 90%, #ffcb05 10%);
 background: linear-gradient(110deg, #fff 90%, #ffcb05 10%);

 .green {
  background-image: linear-gradient(90deg, #004f27, #00793a);
  width: 100%;
  padding: 0.25% 1%;
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0 100%);
  position: relative;
  img {
   height: 40px;
   width: auto;
   object-fit: contain;
   cursor: pointer;
  }
 }

 .greenMob {
  background-image: linear-gradient(90deg, #004f27, #00793a);
  width: 100%;
  padding: 5px 15px;
  img {
   height: 40px;
   width: auto;
   object-fit: contain;
   cursor: pointer;
   margin-left: 10px;
  }
 }

 .yellow {
  background-color: #ffcb05;
  padding: 0.25% 5%;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  width: 100%;
  // position: absolute;
  right: 38px;

  a {
   text-decoration: none;
   color: #004f27;
   font-weight: 600;
  }
 }
}
.sideNavBg {
 background-image: linear-gradient(90deg, #004f27, #00793a);
 cursor: pointer;
 overflow: scroll;
 color: #fff;
 p {
  margin-bottom: 0;
 }
}
