.TouramentBanner {
    background: lightgreen;
    width: 100%;
    height: 180px;
    border-radius: 15px;
    
      }
      .ExpandTableWrapper {
        position: relative;
      }
      
      .expand-table {
        .ant-table-thead {
          padding-left: 30px;
        }
      }
      