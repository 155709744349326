.ExpandListWrapper {
  width: 100%;

  .ListTitleWrapper {
    width: 100%;
    height: 35px;
    background: #ffcb05;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: left;

    .ListTitle {
      color: #000;
      margin-top: auto;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

.expand-table {
  .ant-table-thead {
    padding-left: 30px;
  }
}

.ScheduleMatchWrapper {
  background: rgb(235, 233, 233);
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  margin-bottom: 6px;

  .TeamSection {
    height: 100%;
    align-items: center;

    padding: 6px;
  }
}

.ScheduleMatchWrapperyellowbg {
  background: #ffffe0;
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;

  .TeamSection {
    height: 100%;
    align-items: center;

    padding: 6px;
  }
}

.MatchInfoSection {
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  border-left: solid;
  border-width: 1px;
  border-color: gray;
  display: flex;
  justify-content: center;
  font-size: small;
}

.Teamimg {
  height: 70px;
  width: 70px;
  object-fit: contain;
  border-radius: 10px;
}
