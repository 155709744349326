body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor {
  cursor: pointer;
}

svg {
  vertical-align: baseline !important;
}

.ant-menu-submenu-title {
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background: #fff !important;
  }
}

.ant-menu-submenu-title:hover {
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background: #ffcb03 !important;
  }
}

.ant-menu-sub.ant-menu-inline,
.ant-menu > .ant-menu-item {
  background-image: linear-gradient(90deg, #004f27, #00793a) !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: #007237;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #007237 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #007237 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #007237 !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon::after {
  background: #007237 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #007237 !important;
  color: #007237 !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  width: max-content;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  width: max-content;
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.ant-drawer-header {
  background-color: #ffcb05 !important;
}

.ant-drawer-body {
  background-color: #ffcb05;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-picker {
  border-radius: 5px !important;
}

thead > tr > th {
  color: #000 !important;
  background: #ffcb03 !important;
}
.center-aligned {
  th {
    //  background: #007237 !important;
    text-align: center !important;
  }
  tr {
    // background: #007237 !important;
    text-align: center;
    //justify-content: space-between;
  }
}
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
.expand-table.yellow-expand-table {
  thead > tr > th {
    color: #000 !important;
    background: #ffcb03 !important;
  }
}

.expand-table.green-expand-table {
  thead > tr > th {
    color: #fff !important;
    background: #007237 !important;
  }
}

.expand-table thead > tr > th,
.small-table thead > tr > th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.expand-table thead > tr > th:first-child {
  padding-left: 40px !important;
}

.expand-table tbody > tr > td,
.small-table tbody > tr > {
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  //background-color: #007237;
}

.expand-table .ant-table-placeholder {
  display: none;
}

.expand-table-common-icon .ant-table-row-expand-icon {
  background: transparent;
  border: 1px solid #000;

  &:hover {
    color: #000;
  }

  .yellow-expand-table & {
    background: transparent;
    border: 1px solid #000;
    color: #000;

    &:hover {
      color: #000;
    }
  }

  .green-expand-table & {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
}

tbody:before {
  content: "-" !important;
  display: block !important;
  line-height: 20% !important;
  color: transparent !important;
}

th,
tr {
  padding: 1em !important;
  background: #f2f2f2 !important;
  border-bottom: 2px solid transparent !important;
}

th:first-child,
td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

th:last-child,
td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

// .ant-upload-list-picture-card .ant-upload-list-item {
//  padding: 0 !important;
//  background-color: #ffcb05 !important;
//  border-color: #ffcb05 !important;
// }
.ant-tabs-tab {
  color: #fff !important;
  background-color: #007237 !important;
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
  border-color: transparent !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007237 !important;
  font-weight: 500;
}

.ant-tabs-tab-active {
  color: #007237 !important;
  background-color: #ffcb05 !important;
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
  border-color: transparent !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 5px solid #ffcb05 !important;
}

// .ant-upload.ant-upload-select-picture-card > .ant-upload {
//  background-color: #ffcb05 !important;
//  border-color: #ffcb05 !important;
// }
.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 100% !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  text-align: center !important;
  vertical-align: top !important;
  background-color: transparent !important;
  border: 1px dashed transparent !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  transition: border-color 0.3s !important;
  border-radius: 50% !important;
}

.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload {
  width: 100% !important;
  height: 100% !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  text-align: center !important;
  vertical-align: top !important;
  background-color: transparent !important;
  border: 1px dashed transparent !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  transition: border-color 0.3s !important;
  border-radius: 50% !important;
}

.modalStyle .ant-modal-header {
  background: none;
}
.modalStyle .ant-modal-content {
  border-radius: 15px;
  border: 1px solid #00793a;
}
.transparent-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

// .transparent-modal .ant-modal-mask {
//   background-color: transparent !important;
// }

.ant-modal-mask {
  background-color: transparent !important;
}

.overlay-table {
  table {
    table-layout: fixed !important;
  }
  background-color: transparent;

  .ant-table {
    background-color: transparent;
  }
  thead {
    .ant-table-cell {
      background-color: #27521c !important;
      color: #ede303 !important;
      text-align: center !important;
      font-size: 1vw !important;
      padding: 0.4vw !important;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1000px) {
        & {
          font-size: 11px !important;
        }
      }
    }
  }

  tbody {
    th {
    }
    tr {
      text-align: center;
      background-color: #27521c !important;
      color: white !important;
      td {
        font-size: 1.2vw !important;
        border: none !important;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        padding: 8px 1.5% !important;
        @media (max-width: 1000px) {
          & {
            font-size: 11px !important;
          }
        }
      }
    }
  }
  tbody > tr:hover > td {
    background: rgb(0, 89, 43) !important;
  }
}
.image_only_card {
  .ant-card-body {
    padding: 0px !important;
  }
}

.ant-card-body {
  padding: 8px !important;
}
