.ScoreCardWrapper {
  margin: 0;
  width: 450px;
  border: 2px solid #059f4f;
  border-radius: 12px;
  padding: 11px;
  height: 280px;
  cursor: pointer;

  // background: whitesmoke;
  // border: 1px solid #a5a7a6;

  & > div {
    cursor: pointer;

    & > div {
      // padding-bottom: 5px;
      // padding-top: 10px;
    }
  }

  .cardHeading {
    font-size: 1rem;
    font-weight: bold;
    //   background: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    line-height: 1.5;
    color: #4a4a4a;
  }

  .cardSubHeading {
    font-size: 0.65rem;
    font-weight: bold;
    width: 100%;
    word-wrap: normal;
    padding-top: 10px;

    span {
      color: #4a4a4a;
      padding-right: 10px;
      position: relative;
      height: 100%;
      display: inline-block;
      word-break: break-word;

      &:after {
        content: "";
        display: inline-block;
        width: 1px;
        position: absolute;
        height: 60%;
        background-color: #4a4a4a;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.5;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .fieldname {
    font-size: 0.75rem;
    font-weight: bold;
    width: 100%;
    word-wrap: normal;
    padding-top: 10px;
    color: #4a4a4a;
    padding-right: 10px;
    position: relative;
    height: 100%;
    display: inline-block;
    word-break: break-word;
    // background-color: #ffcb03;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cardScore {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
  }

  .cardScoreItem {
    display: flex;
    align-items: center;
    // padding: 0 0 10px 0;
    //background-color: #059f4f;
  }

  .cardPlayerScore {
    // display: flex;
    // align-items: center;
    margin-bottom: 5px;
    // padding: 0 0 10px 0;
    // background-color: #059f4f;
    min-height: 45.5px;
  }
  .cardScorePlayer {
    // flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    // line-height: 1;
    // flex-wrap: wrap;
    color: #000000;
    // background-color: #ffcb03;
    //  min-height: 0px;

    & > span {
      padding: 0 5px;
      color: #4a4a4a;
      font-weight: 600;
      // white-space: nowrap;
      // width: 105px;
      // background-color: #ffcb03;
      // overflow: hidden;
      // text-overflow: ellipsis;
      align-items: center;
      justify-content: center;
      // background-color: #ebebe4;

      span {
        font-size: 0.6rem;
        padding-left: 5px;
      }
    }
  }
  .cardScoreLogo {
    flex: 0 0 auto;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .cardScoreTeam {
    flex: 1 1 auto;
    display: flex;
    // background: #000;
    width: 56%;
    align-items: center;
    // justify-content: center;
    font-size: 0.9rem;
    // line-height: 1;
    // flex-wrap: wrap;
    color: #000000;
    // background-color: #ffcb03;
    min-height: 40px;

    & > span {
      padding: 0 5px;
      font-weight: bold;
      // white-space: nowrap;
      // width: 105px;
      // background-color: #ffcb03;
      // overflow: hidden;
      // text-overflow: ellipsis;
      align-items: center;
      justify-content: space-between;
      // background-color: #ebebe4;

      &:first-child {
        font-weight: bold;
      }

      span {
        font-size: 0.6rem;
        padding-left: 5px;
      }
    }
  }

  .cardScorePlayIcon {
    flex: 0 0 auto;
  }

  .cardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardScoreLiveStatus {
    flex: 0 0 auto;
    display: flex;
    color: #fff;
    line-height: 1;

    & > div {
      background-color: red;
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 50px;

      img {
        height: 15px;
        width: 15px;
      }

      span {
        padding: 2px;
        display: flex;
        line-height: 1;

        &:last-child {
          padding: 0 5px 0 3px;
          font-weight: bold;
        }
      }
    }
  }

  .cardScoreScheduledStatus {
    flex: 0 0 auto;
    display: flex;
    color: #fff;
    line-height: 1;

    & > div {
      background-color: #ebebe4;
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 50px;

      img {
        height: 15px;
        width: 15px;
      }

      span {
        padding: 2px;
        display: flex;
        line-height: 1;

        &:last-child {
          padding: 0 5px 0 3px;
          font-weight: bold;
        }
      }
    }
  }

  .cardScoreHighLightStatus {
    flex: 1 1 auto;
    text-align: center;

    span {
      &:last-child {
        color: #007237;
        font-weight: bold;
        padding: 0 5px;
      }
    }
  }

  .cardScoreButton {
    flex: 0 0 auto;
    //  margin-right: -20px;

    button {
      background-color: #ffcb03;
      color: #5a5548;
      height: 30px;
      padding: 0px 5px;
      font-size: 16px;
      border-radius: 0;
      position: relative;
      box-shadow: none;
      border: none;
      display: flex;
      align-items: center;
      margin-left: 20px;

      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: -20px;
        top: 50%;
        border-top: 30px solid transparent;
        border-right: 20px solid #ffcb03;
        border-bottom: 0px solid transparent;
        opacity: 1;
        background: none;
        transform: translateY(-50%);
      }
    }

    span {
      font-size: 00.75rem;
      line-height: 1;

      &:last-child {
        padding: 0 5px;
      }
    }
  }
}
.cardScoreTeamWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //background: red;
  .cardScoreTeamScore {
    display: flex;
    width: max-content;
    height: 20px;
    margin-right: 5px;
    //  background: green;
    font-weight: bold;
  }
}
