.CsaStepperWrapper {
 .steps {
  padding-right: 20%;
  padding-left: 20%;
 }
}

@media (min-width: 320px) and (max-width: 900px) {
 .CsaStepperWrapper {
  .steps {
   padding-right: 0%;
   padding-left: 0%;
   justify-content: center;
  }
 }
}
