.StatisticsWrapper {
    padding: 20px 25px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .StatisticsStatus {
        display: flex;
        color: #007237;
        width: 100%;
        flex: 0 0 auto;
        flex-wrap: wrap;

        @media (min-width: 1125px) {
            & {
                flex-wrap: nowrap;
            }
        }
    }
}

.StatisticsWinner {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    position: relative;

    .StatisticsMatchStatus {
        font-weight: 600;
        font-size: 1rem;

        span {
            padding-right: 8px;
        }

        @media (max-width: 1124px) {
            & {
                display: none;
            }
        }
    }

    .StatisticsWinnerResult {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 20px 0;
        letter-spacing: 1px;

        .StatisticsWinnerName {
            font-weight: 600;
            font-size: 1.5rem;
        }

        .StatisticsWinnerStatus {
            font-weight: 600;
            font-size: 1rem;
        }

        @media (max-width: 1124px) {
            & {
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .StatisticsWinnerName {
                    font-weight: 600;
                    font-size: 1rem;
                    padding-right: 7px;
                }

                .StatisticsWinnerStatus {
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
        }
    }

    @media (min-width: 1125px) {
        & {
            flex: 1 1 25%;
        }
    }

    @media (max-width: 1124px) {
        & {
            width: 100%;
            flex: 1 1 auto;
        }
    }
}

.StatisticsDivider {
    display: inline-block;
    height: 100%;
    margin: 0 10px;
    min-width: 30px;
    position: relative;

    div {
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #007237;
        opacity: 0.5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
    }

    @media (min-width: 1125px) {
        & {
            flex: 1 1 5%;
        }
    }

    @media (max-width: 1124px) {
        & {
            display: none;
        }
    }
}

.StatisticsTeam {
    display: flex;

    .StatisticsTeamScore {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .StatisticsTeamName {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .StatisticsTeamScoreValue {
        font-weight: 600;
        font-size: 1.5rem;
        padding: 20px 1rem;
        line-height: 1;
    }

    .StatisticsTeamOver {
        line-height: 1;
        display: flex;
        flex-direction: column;

        span {
            font-size: 0.75rem;

            &:last-child {
                padding-top: 5px;
            }
        }
    }

    @media (min-width: 1125px) {
        & {
            flex: 1 1 70%;
        }
    }

    @media (max-width: 1124px) {
        & {
            width: 100%;
            flex: 1 1 auto;
        }
    }

    @media (max-width: 768px) {
        & {
            width: 100%;
            flex: 1 1 auto;
        }
    }

    @media (max-width: 692px) {
        & {
            .StatisticsTeamScore {
                order: 2;
                width: 100%;
            }

            .StatisticsTeamOver {
                align-items: center;
            }
        }
    }
}

.StatisticsTeamLogo {
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #007237;
        border-radius: 5px;
        padding: 10px;
    }

    img {
        max-width: 100px;
        width: 100%;
        height: 100px;
        min-width: 50px;
    }
}

.StatisticsVersus {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;

    @media (max-width: 692px) {
        & {
            padding-top: 10%;
            align-items: start;
        }
    }
}

.StatisticsHeader {
    display: flex;
    justify-content: space-between;

    .heading {
        color: #007237;
        font-size: 25px;
        flex: 1;
    }
}

.StatisticsTeamDetail {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    flex-wrap: nowrap;

    &:first-child {
        justify-content: end;
    }

    @media (max-width: 692px) {
        & {
            flex-wrap: wrap;
            justify-content: center;

            &:first-child {
                justify-content: center;
            }
        }
    }
}

.StatisticsData {
    flex: 1 1 auto;
    margin: 20px 0;

    &>div>div:first-child {
        margin-bottom: 0;
        padding: 0 10px;

        &:before {
            border-bottom: none;
        }
    }
}

@media (max-width: 1124px) {}


.tablecontainer {
    tr:nth-child(odd) {
        background-color: #f2f2f2 !important;
    }

    tr:nth-child(even) {
        background-color: #FFFFE0 !important;
    }
}