.CsaFooterWrapper {
  height: auto;
  padding: 0;
  width: 100%;
  position: relative;
  bottom: 0;
  min-height: 70px;
  background: -webkit-linear-gradient(110deg, #ffcb05 10%, #fff 10%);
  background: -o-linear-gradient(110deg, #ffcb05 10%, #fff 10%);
  background: -moz-linear-gradient(110deg, #ffcb05 10%, #fff 10%);
  background: linear-gradient(110deg, #ffcb05 10%, #fff 10%);
  .yellow {
    background-color: #ffcb05;
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0 100%);
    height: 70px;
    position: absolute;
    width: 100%;
    left: 3%;
    bottom: 0;
    img {
      height: 40px;
      object-fit: contain;
      width: auto;
      margin-top: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .tnc2 {
    color: #000000c9;
    font-size: 13px;
    text-decoration: none;
  }
  :hover.tnc2 {
    text-decoration: underline;
  }
  .green {
    height: auto;
    background-image: linear-gradient(90deg, #004f27, #00793a);
    clip-path: polygon(4% 0, 100% 0%, 100% 100%, 0 100%);
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    padding: 8px 12px;
    padding-left: 50px;
    p {
      margin-bottom: 0;
    }
    .copyRight {
      color: #fff;
      font-size: 16px;
    }
    .tnc {
      color: #fff;
      font-size: 13px;
      text-decoration: none;
    }
    :hover.tnc {
      text-decoration: underline;
    }
    img {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }

  .mobFooter {
    background-color: #ffcb05;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    img {
      height: 40px;
      object-fit: contain;
      width: auto;
      cursor: pointer;
    }

    .copyRight {
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
