.LoginWrapper {
    background-color: #004f27;

    // min-height: 100vh;
    .grid {
        height: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgWrap {
        // height: 270px;
        width: 270px;
        //   border-radius: 50%;
        //   background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 2px solid green;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            // border-radius: 50%;
            object-fit: contain;
        }
    }
}