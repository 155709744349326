.ProgressCard {
  margin: 0;
  aspect-ratio: 10.6/8;
  height: 180px;
  box-shadow: #059f4f 0px 1px 20px;
  cursor: pointer;

  .imageWrapper {
    display: flex;
    aspect-ratio: 12/8;
    height: 150px;

    img {
      object-fit: cover;
    }
  }

  .emptyimgWrapper {
    display: flex;
    width: 100%;

    div {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      // object-fit: contain;
      align-items: center;
    }

    .emptylogo {
      height: 80px;
      width: 80px;
    }
  }

  p {
    padding: 3px 10px;
    color: black;
    font-weight: 500;
  }
}
