.ovWrapper {
  width: 100%;
  aspect-ratio: 14/1;
  min-height: 70px;
  position: fixed;
  bottom: 2.1vw;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 0.2vw;

  h1 {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 3vw;
    line-height: 100%;
    text-transform: uppercase;
    //  text-overflow: ellipsis;
  }
  p {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1vw;
    line-height: 100%;
    text-transform: uppercase;
  }

  h5 {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-bottom: 0px;
    font-size: 1.45vw;
    line-height: 100%;
    text-transform: uppercase;
  }
}

.topSectionWraper {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  gap: 0.4vw;
}
.teamName {
  width: 30%;
  background-color: #27521c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5vw;
  gap: 2%;
  h5 {
    color: #ede303;
    font-weight: 500;
  }
  img {
    height: 50%;
  }
}
.runRate {
  width: 40%;
  border-radius: 0.5vw;
  background-color: #27521c;
  font-weight: 400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomSectionWrapper {
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.teamLogo {
  width: 6%;
  height: 100%;
  object-fit: contain;
  border-radius: 0.6vw;
  padding: 5px;
  aspect-ratio: 1/1;
}
.playerInfo {
  width: 31%;
  border-radius: 0.6vw;
  background-color: #ede303;
  color: #27521c;
  font-weight: 500;
  display: flex;
  //  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // gap: 10%;

  .playerInfoText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
    min-width: 75%;
    width: 100%;
    height: 100%;
  }

  .chasing {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    h5 {
      color: #27521c;
    }
  }

  .playerName {
    width: 72%;
    color: #27521c;
    font-weight: 500;
    white-space: nowrap;
    overflow: visible;
  }
  .bowlerName {
    width: 72%;
    color: #27521c;
    font-weight: 500;
    white-space: nowrap;
    overflow: visible;
  }
  .playerStat {
    color: #27521c;
    font-weight: 500;
    line-height: 85%;
  }

  .playerInfo1 {
    width: 90%;
    display: flex;
    justify-content: space-between;

    div {
      font-weight: 400;
      display: flex;
      align-items: end;
      gap: 0.3vw;
    }
  }

  .overInfo {
    width: 90%;
    display: flex;
    justify-content: start;
    gap: 3%;
    font-weight: 400;
  }
  .deliveries {
    width: 85%;
    display: flex;
    gap: 2%;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .bowlerStat {
    color: #27521c;
    font-weight: 400;
    white-space: normal;
    text-overflow: visible;
  }
  .overText {
    width: min-content;
    color: #27521c;
    font-weight: 400;
  }

  // @media (max-width: 720px) {
  //   & {
  //     gap: 5%;
  //   }
  // }
}
.gif {
  border-radius: 0.6vw;
  width: 26%;
  object-fit: cover;
}
.scoreInfo {
  width: 26%;
  border-radius: 0.6vw;
  background-color: #27521c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  padding: 0px 2%;
  color: #ede303;

  .scoreRuns {
    width: 60%;
    // font-size: 46px;
    // line-height: 46px;
    font-weight: 700;
    color: white;
  }

  .scoreOvers {
    //  background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
      color: #ede303;
      font-weight: 400;
    }
    > * {
      margin-bottom: 5%;
      @media (max-width: 1156px) {
        & {
          margin-bottom: 5%;
        }
      }
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
