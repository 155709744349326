.MyProfileWrapper {
 padding: 20px 25px 20px;
 .heading {
  color: #007237;
  font-size: 25px;
 }

 .contents {
  padding: 10px 0;
 }

 thead > tr > th {
  color: #000;
  background: #ffcb03;
 }
}
.imageUpload {
 display: flex;
 justify-content: center;
}
.name {
 color: #000000;
 margin-bottom: 2px;
 font-size: 15px;
}

.required {
 color: red;
 margin-left: 5px;
}
.CsaMoreMenuWrapper {
 .cursor {
  cursor: pointer;
 }
 padding-left: 95%;
 padding-bottom: 1%;
 padding-right: 5%;
 background: transparent !important;
 .menuMore {
  color: #fff !important;
  border: transparent !important;
 }
}

@media (min-width: 320px) and (max-width: 900px) {
 .MyProfileWrapper {
  .contents {
   padding: 50px 10px;
  }
 }
}
