.ovWrapper {
  width: 100%;
  aspect-ratio: 19.2/1.5;
  min-height: 70px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .logo {
    margin-top: 2%;
    margin-left: 5%;
    width: 12vw;
    min-width: 150px;
    min-height: 100px;
    object-fit: contain;
  }
  .logo2 {
    margin-top: 2%;
    margin-right: 5%;
    width: 150px;
    height: 100px;
    object-fit: contain;
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
