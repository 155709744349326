.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  h3 {
    margin-bottom: 0px;

    font-size: 2vw;
  }

  p {
    margin-bottom: 0px;
    font-size: 1.2vw;
  }
}
.teamNameWrapper {
  background-color: #ede303;
  width: 54%;
  border-radius: 0.5vw;
  padding: 1% 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    width: 56%;
    gap: 5px;
  }

  h3 {
    color: #27521c;
    font-weight: 650;
    width: auto;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.7vw;
    color: #27521c;
    width: 50%;
    h3 {
      font-weight: 600;
      white-space: nowrap;
      text-align: end;
    }
  }
}
.logoTableContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  .teamLogo {
    width: 11%;
    min-width: 100px;
    border-radius: 5%;
    border: solid 2px gray;
  }
}
.tableWrapper {
  width: 52%;
  height: 100%;
  border-radius: 10px;
  font-size: 15px;
  .extrasRow {
    width: 100%;
    background-color: #27521c;
    margin-bottom: 2px;
    color: #ede303;
    border-radius: 13px;
    padding: 7px 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
