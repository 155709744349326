.GoogleSearchInputWrapper {
    margin-bottom: 10px;

    .name {
        color: #000000;
        margin-bottom: 2px;
        font-size: 15px;
    }

    .nameWhite {
        color: #fff;
        margin-bottom: 2px;
        font-size: 15px;
    }

    .required {
        color: red;
        margin-left: 5px;
    }

    .erroMsg {
        span {
            font-size: 12px;
        }
    }
}