.ExpandListWrapper {
  width: 100%;

  .ListTitleWrapper {
    width: 100%;
    height: 35px;
    background: #ffcb05;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;

    .ListTitle {
      color: #000;
      margin-top: auto;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

.expand-table {
  .ant-table-thead {
    padding-left: 30px;
  }
}

.ScheduleMatchWrapper {
  background: rgb(235, 233, 233);
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  margin-bottom: 8px;
  .TeamSection {
    height: 100%;
    align-items: center;
    padding: 6px;
  }
}

.ScheduleMatchWrapperyellowbg {
  background: #ffffe0;
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.TeamSection1 {
  height: 100%;
  padding: 6px;
}

.MatchInfoSection {
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  border-left: solid;
  border-width: 1px;
  border-color: gray;
  display: flex;
  font-size: small;
  //  flex-direction: column;
  align-items: center;
  // background: green;
  justify-content: center;
}
.TeamSection2 {
  display: flex;
  // padding: auto;
  padding-top: 7px;
  padding-right: 15px;

  @media (max-width: 990px) {
    & {
      display: flex;
      flex-direction: column;
    }
  }
}

.vs {
  width: fit-content;
  margin-top: 35px;
  font-weight: 600;
  @media (max-width: 990px) {
    & {
      width: fit-content;
      align-self: center;
      font-weight: 600;
    }
  }
}
.Teamimg {
  height: 70px;
  width: 70px;
  object-fit: contain;
  border-radius: 10px;
}
.teamNameW {
  display: flex;
  justify-content: start;
  width: 100%;
  min-height: 75px;
  padding: 10px;
}
.teamName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 75px;
  padding: 10px;
}
.teamDetailsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  margin-left: 10px;
}
.teamDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
}
.teamDetails2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
}
.BattingScore {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    height: 22px;
  }
}
.scoresWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}
.streamUrlWrapper {
  position: relative;
}
.copyButton {
  position: absolute;
  bottom: 15%;
  right: 10px;
}
