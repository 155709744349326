.ChangePasswordWrapper {
 padding: 20px 25px 20px;
 .heading {
  color: #007237;
  font-size: 25px;
 }

 .contents {
  padding: 10px 0;
 }
}

@media (min-width: 320px) and (max-width: 900px) {
 .ChangePasswordWrapper {
  .contents {
   padding: 50px 10px;
  }
 }
}
