.CsaCheckBoxWrapper {
 margin-bottom: 10px;

 .name {
  color: #000000;
  margin-bottom: 2px;
  font-size: 15px;
 }

 .required {
  color: red;
  margin-left: 5px;
 }
}
